import React, { useState } from 'react';
import Button from '@ingka/button';
import Hyperlink from '@ingka/hyperlink';
import { CheckboxInput } from 'components/common/CheckboxInput';
import Loading, { LoadingBall } from '@ingka/loading';
import SpeechBubble from '@ingka/ssr-icon/paths/speech-bubble';
import { useSaveSurveyMutation } from '__generated__/types';
import { SurveyModal } from './SurveyModal';
import * as Styled from 'components/seo/styles';
import { SurveyRating } from './SurveyRating';
import Text from '@ingka/text';

export const FeedbackSurvey: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState('');
  const [rating, setRating] = useState<number | undefined>();
  const [contactMe, setContactMe] = useState<boolean>(false);
  const initialState = {
    data: false,
    error: false,
    sent: false,
  };
  const [{ data, error, sent }, setResult] = useState<{ data: boolean; error: boolean; sent: boolean }>(initialState);
  const [saveSurvey, { loading }] = useSaveSurveyMutation();

  const resetForm = () => {
    setIsOpen(false);
    setResult(initialState);
    setText('');
    setRating(undefined);
    setContactMe(false);
  };

  const submitForm = async () => {
    const res = await saveSurvey({ variables: { survey: { question: 'feedback', answer: text, rating, contactMe } } });
    setResult({ data: !!res.data?.saveSurveyAnswer, error: !res.data?.saveSurveyAnswer, sent: true });
  };

  return (
    <Styled.FeedbackButtonContainer>
      <Button
        type={'primary'}
        expanding
        text={'Give feedback'}
        size={'small'}
        ssrIcon={SpeechBubble}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <SurveyModal
        isVisible={isOpen}
        question={'Help us improve'}
        onClickClose={() => setIsOpen(false)}
        buttons={
          <>
            <Button text={sent ? 'Finish' : 'Cancel'} type={sent ? 'primary' : 'secondary'} size={'small'} onClick={() => resetForm()} />
            {!sent && (
              <Button
                text="Send feedback"
                type="primary"
                size={'small'}
                disabled={(!text.trim() && !rating) || loading}
                onClick={async () => submitForm()}
              />
            )}
          </>
        }
      >
        <Text>How is your experience using Optimera?</Text>
        <Text>Your feedback help us better understand if we are creating value and where we need to improve.</Text>
        <Text>
          If you wish to report a bug, please refer to this{' '}
          <Hyperlink newWindow text={'Jira portal'} url={'https://jira.digital.ingka.com/servicedesk/customer/portal/16/create/961'} />.
        </Text>
        {loading && (
          <Loading text={'Please wait...'}>
            <LoadingBall />
          </Loading>
        )}
        {!loading && !sent && (
          <>
            <SurveyRating rating={rating} onChange={setRating} />
            <Styled.SurveyTextArea
              label={'Share your feedback (optional)'}
              id="feedback-text-area"
              disabled={loading || sent}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <CheckboxInput
              data-testid={'survey-contact-me'}
              shouldValidate={false}
              id={'surveyContactMe'}
              label={'You may contact me about this feedback'}
              onChange={() => setContactMe(!contactMe)}
              checked={contactMe}
            />
          </>
        )}
        {!loading && sent && (
          <>
            <Styled.ConfirmationMessage>
              {data && !error && '✅ Thank you for you feedback!'}
              {!data && error && '⚠️ An error occurred! Please try again later.'}
            </Styled.ConfirmationMessage>
            {data && <p></p>}
          </>
        )}
      </SurveyModal>
    </Styled.FeedbackButtonContainer>
  );
};
