import React from 'react';
import { useHistory } from 'react-router-dom';
import { TabPanel } from '@ingka/tabs';
import { DashboardVersion } from 'pages/Dashboard';
import { FlexContainer } from 'components/FlexContainer';
import { DashboardCard, DashboardCardProps } from './DashboardCard';
import { DashboardRedirectCard } from './DashboardRedirectCard';
import * as Styled from './styles';

type DashboardTabPanelProps = {
  activeTab: string;
  selectedVersions: DashboardVersion[];
  selectedLanguage: string;
};
const getTabsForSelectedVersionType = (versions: DashboardVersion[], pushHistory: (path: string) => void) => {
  return versions.map(({ id, size, optimizedItems, needsReview, type }) => {
    const percentOptimized = size ? Math.round((optimizedItems / size) * 100) : 0;
    const cards: DashboardCardProps[] = [
      {
        type: 'negative',
        title: 'Non optimized',
        text: `${size - optimizedItems}`,
        onClick: () => pushHistory(`/metadata/view/${id}?optimized=false`),
      },
      {
        type: 'cautious',
        title: 'Needs review',
        text: `${needsReview}`,
        onClick: () => pushHistory(`/metadata/view/${id}?needsReview=true`),
      },
      { type: 'positive', title: 'Total optimized', text: `${optimizedItems}` },
      { type: 'informative', title: 'Optimized', text: `${percentOptimized}%` },
    ];
    return { type, cards };
  });
};

export const DashboardTabPanel: React.FC<DashboardTabPanelProps> = ({ activeTab, selectedLanguage, selectedVersions }) => {
  const history = useHistory();
  const metadataTabs = getTabsForSelectedVersionType(selectedVersions, history.push);
  const redirectTabs = ['redirect', 'shorturl'];
  return (
    <TabPanel key={activeTab} tabPanelId={activeTab} style={{ padding: 0 }}>
      {redirectTabs.includes(activeTab) ? (
        <DashboardRedirectCard language={selectedLanguage} activeTab={activeTab} />
      ) : (
        <>
          {metadataTabs
            .filter((tab) => tab.type === activeTab)
            .map((metadataTab) => (
              <FlexContainer direction={'row'} gap={0.4} key={metadataTab.type}>
                {metadataTab.cards.map((props) => (
                  <DashboardCard key={props.type} {...props} />
                ))}
              </FlexContainer>
            ))}
          {!metadataTabs.some((tab) => tab.type === activeTab) && (
            <Styled.DashboardTabPanelsMissingDataWrapper>
              <FlexContainer justify="center">
                <h3>There is no data for {activeTab}</h3>
              </FlexContainer>
            </Styled.DashboardTabPanelsMissingDataWrapper>
          )}
        </>
      )}
    </TabPanel>
  );
};
