import { Dispatch } from 'react';
import { Action, createDataContext } from './createDataContext';
import { request } from 'gaxios';

export interface SiteStructure {
  _id: string;
  id?: string;
  slug: string;
  description: string;
  owner: string;
  type: string;
  allowLocalSections: boolean;
  allowLocalPages: boolean;
  allowLocalApps: boolean;
  allowPlanners: boolean;
  startDate?: string;
  endDate?: string;
}

interface State {
  data: SiteStructure[];
}

const siteStructureReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'updateAll':
      return { ...state, data: action.payload };
    case 'delete':
      return { ...state, data: state.data.filter((siteStructure) => siteStructure._id !== action.payload) };
    case 'add':
      return { ...state, data: [...state.data, action.payload] };
    case 'edit':
      return {
        ...state,
        data: state.data.map((siteStructure) => (siteStructure._id === action.payload._id ? action.payload : siteStructure)),
      };
    default:
      return state;
  }
};

const fetchAll = (dispatch: Dispatch<Action>) => async (token: string) => {
  const { data } = await request<SiteStructure[]>({
    url: `/api/optimera/siteStructure`,
    headers: { authorization: `Bearer ${token}` },
  });

  dispatch({ type: 'updateAll', payload: data });
};

const deleteOne = (dispatch: Dispatch<Action>) => async (_id: string, token: string) => {
  await request({
    method: 'DELETE',
    url: `/api/optimera/siteStructure/${_id}`,
    headers: { authorization: `Bearer ${token}` },
  });

  dispatch({ type: 'delete', payload: _id });
};

const editOne = (dispatch: Dispatch<Action>) => async (siteStructure: SiteStructure, token: string) => {
  const { data } = await request<SiteStructure>({
    method: 'POST',
    url: `/api/optimera/siteStructure/${siteStructure._id}`,
    data: siteStructure,
    headers: { authorization: `Bearer ${token}` },
  });

  dispatch({ type: 'edit', payload: data });
};

const addNew = (dispatch: Dispatch<Action>) => async (siteStructure: SiteStructure, token: string) => {
  const { data } = await request<SiteStructure>({
    method: 'PUT',
    url: `/api/optimera/siteStructure`,
    data: siteStructure,
    headers: { authorization: `Bearer ${token}` },
  });

  dispatch({ type: 'add', payload: data });
};

export const { Provider, Context } = createDataContext<State>(
  siteStructureReducer,
  {
    fetchAll,
    deleteOne,
    editOne,
    addNew,
  },
  { data: [] }
);
