import React, { Component, ReactNode } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Routes } from './pages/Routes';
import { Provider as LoginProvider } from './hooks/contexts/LoginContext';
import '@ingka/base/dist/style.min.css';
import '@ingka/breadcrumb/dist/style.min.css';
import './App.css';

class App extends Component {
  render(): ReactNode {
    return (
      <LoginProvider>
        <CookiesProvider>
          <Routes />
        </CookiesProvider>
      </LoginProvider>
    );
  }
}

export default App;
