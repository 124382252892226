import styled from '@emotion/styled';
import Select from '@ingka/select';
import { radiusS, space100, space75, thicknessThin } from '@ingka/variables';
import { colourNeutral3 } from '@ingka/variables/colours-css';

export const DashboardHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${space100};
`;

export const LanguageDropdown = styled(Select)`
  option[value='Choose an option'] {
    display: none;
  }

  width: 200px;
`;

export const DashboardSection = styled.div`
  border: ${thicknessThin} solid ${colourNeutral3};
  border-radius: ${radiusS};
  padding: ${space75};
  display: flex;
  flex-direction: column;
  gap: ${space100};
`;
