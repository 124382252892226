import { Dispatch, ReactElement } from 'react';
import { Action, createDataContext } from './createDataContext';

export interface InlineMessageActions {
  text?: string | ReactElement<any>;
  onClick?: (...args: any[]) => any;
  href?: string;
  ssrIcon?: (...args: any[]) => any;
  iconPosition?: 'leading' | 'trailing';
  loading?: boolean;
}

export interface StatusMessageState {
  isVisible: boolean;
  variant: 'positive' | 'cautionary';
  title: string;
  bodyText: string;
  actions?: InlineMessageActions[];
}

const statusMessageReducer = (state: StatusMessageState, action: Action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const setStatusMessage = (dispatch: Dispatch<Action>) => (payload: StatusMessageState) => {
  dispatch({ type: 'set', payload: { ...payload } });
};

export const { Provider, Context } = createDataContext<StatusMessageState>(
  statusMessageReducer,
  { setStatusMessage },
  { isVisible: false, variant: 'positive', bodyText: '', title: '', actions: undefined }
);
