import { Dispatch } from 'react';
import { Action, createDataContext } from './createDataContext';

type MetaDataContextParams = { country: string; language: string; type: string; versionId: string; userCanEdit: boolean; isDraft: boolean };

interface State {
  metaDataContext: MetaDataContextParams;
  isEditorOpen: boolean;
  activeItemId?: string;
}

const metaDataReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'set_metadata_context_values':
      return { ...state, metaDataContext: action.payload };
    case 'setEditorOpen':
      const { isEditorOpen, activeItemId } = action.payload;
      return { ...state, isEditorOpen, activeItemId };
    default:
      return state;
  }
};

const setMetaDataContext = (dispatch: Dispatch<Action>) => (metaDataContext: MetaDataContextParams) => {
  dispatch({ type: 'set_metadata_context_values', payload: metaDataContext });
};

const setIsEditorOpen = (dispatch: Dispatch<Action>) => (isEditorOpen: boolean, activeItemId?: string) => {
  dispatch({ type: 'setEditorOpen', payload: { isEditorOpen, activeItemId } });
};

export const { Provider, Context } = createDataContext<State>(
  metaDataReducer,
  {
    setMetaDataContext,
    setIsEditorOpen,
  },
  {
    metaDataContext: { country: '', language: '', type: '', versionId: '', userCanEdit: false, isDraft: false },
    isEditorOpen: false,
  }
);
