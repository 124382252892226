import { DependencyList, useContext, useEffect, useState } from 'react';
import { GaxiosOptions, GaxiosResponse, request } from 'gaxios';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';

const useWithToken = <T>(cb: (token: string) => Promise<T>, deps: DependencyList): { loading: boolean; error?: string; data?: T } => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [data, setData] = useState<T>();
  const { refreshToken } = useContext(LoginContext);
  useEffect(() => {
    setLoading(true);
    const makeCallWithToken = async () => {
      try {
        const token: string = await refreshToken();
        const data = await cb(token);
        setData(data);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    };
    makeCallWithToken();
  }, deps);
  return { loading, error, data };
};

export const useRequestWithTokenAndTransformer = <T, U>(
  options: GaxiosOptions,
  deps: DependencyList,
  transformer: (resp: T) => U
): { loading: boolean; error?: string; data?: U } => {
  return useWithToken((token): Promise<U> => {
    const req = request<T>({
      ...options,
      headers: { ...options.headers, authorization: `Bearer ${token}` },
    }).then((res: GaxiosResponse<T>) => res.data);
    return req.then(transformer);
  }, deps);
};
