import React, { useContext, useState } from 'react';
import { Row } from '../../common/Row';
import { SiteStructureForm } from './Edit';
import { View } from './View';
import { FlexContainer } from '../../FlexContainer';
import Button from '@ingka/button';
import pencil from '@ingka/ssr-icon/paths/pencil';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import { SiteStructureItemProps } from './SiteStructure';
import { Context as SiteStructureContext } from 'hooks/contexts/SiteStructureContext';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';

export const SiteStructureItem: React.FC<SiteStructureItemProps> = (props) => {
  const [edit, setEdit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { editOne, deleteOne } = useContext(SiteStructureContext);
  const { refreshToken } = useContext(LoginContext);

  return (
    <Row>
      {edit ? (
        <SiteStructureForm data={props.data} setEdit={setEdit} submitAction={(data) => editOne(data, refreshToken)} showId={true} />
      ) : (
        <View data={props.data} />
      )}
      <FlexContainer direction={'row'} align={'center'}>
        <Button
          text={'Edit'}
          ssrIcon={pencil}
          type={'tertiary'}
          iconOnly={true}
          iconPosition={'leading'}
          onClick={() => setEdit(!edit)}
          data-cy={'edit-button'}
        />
        <Button
          text={'Delete'}
          ssrIcon={trashCan}
          type={'tertiary'}
          iconOnly={true}
          iconPosition={'leading'}
          onClick={() => setModalOpen(true)}
          data-cy={'delete-button'}
        />
        <Modal
          escapable={false}
          visible={modalOpen}
          handleCloseBtn={() => {
            setModalOpen(false);
          }}
        >
          <Prompt
            titleId={'Delete'}
            title={'Delete'}
            header={<ModalHeader />}
            footer={
              <ModalFooter>
                <Button
                  text={'No'}
                  type={'secondary'}
                  onClick={() => {
                    setModalOpen(false);
                  }}
                />
                <Button
                  text={'Yes'}
                  type={'primary'}
                  onClick={() => {
                    deleteOne(props.data._id, refreshToken);
                  }}
                />
              </ModalFooter>
            }
          >
            <p>Do you really want to delete the entry?</p>
          </Prompt>
        </Modal>
      </FlexContainer>
    </Row>
  );
};
