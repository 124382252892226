import React, { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HelperText from '@ingka/helper-text';
import Loading, { LoadingBall } from '@ingka/loading';
import checkmarkCircleIcon from '@ingka/ssr-icon/paths/checkmark-circle';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import Text from '@ingka/text';
import { RuleType } from 'features/Rules';
import { RulesDiff } from 'features/Rules/RulesDiff';
import { Context as StatusMessageContext } from 'hooks/contexts/StatusMessageContext';
import { useInterval } from 'hooks/useInterval';
import { useRequestWithTokenAndTransformer } from 'hooks/useWithToken';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import { fetchUploadedRulesByIds } from './rules.service';
import * as Styled from 'pages/styles';

export type RuleTypeDiff = Pick<
  RuleType,
  'name' | 'matchURL' | 'redirectType' | 'targetURL' | 'dateEnd' | 'dateStart' | 'errors' | 'targetStatus' | 'isModified' | 'docRefId'
>;
export type UploadedRules = { uploadedRules: RuleTypeDiff[] };

export const UploadRulesDiff: FC = () => {
  const { id, policy } = useParams<{ id: string; policy: string }>();
  const {
    state: { isVisible, variant, bodyText, title, actions },
    setStatusMessage,
  } = useContext(StatusMessageContext);
  const {
    state: { token },
  } = useContext(LoginContext);

  const { data, loading } = useRequestWithTokenAndTransformer<UploadedRules, UploadedRules>(
    { url: `/api/redirect/rules/${policy}/upload-diff/${id}`, method: 'POST' },
    [id, policy],
    (res) => res
  );
  const [rules, setRules] = useState<RuleTypeDiff[]>([]);
  const [docIdsWithUnknownStatus, setDocIdsWithUnknownStatus] = useState<string[]>([]);
  const [isRequested, setIsRequested] = useState(false);
  const created = rules.filter((rule) => !rule?.isModified);
  const modified = rules.filter((rule) => rule?.isModified);
  const totalRules = created.length + modified.length;
  const amountOfNewRules = created.length;
  const amountOfModifiedRules = modified.length;
  const modifiedErrors = modified.some((r) => r?.errors);
  const createdErrors = created.some((r) => r?.errors);

  useInterval(() => {
    if (docIdsWithUnknownStatus.length < 1) {
      return;
    }
    if (!isRequested && docIdsWithUnknownStatus.length > 0) {
      setIsRequested(true);
      fetchUploadedRulesByIds(policy, id, docIdsWithUnknownStatus, token)
        .then((response) => {
          const rulesWithStatus: RuleTypeDiff[] = response.uploadedRules.filter((rule) => rule.targetStatus);
          const updatedRules: RuleTypeDiff[] = [];
          for (const rule of rules) {
            const updatedRule = rulesWithStatus.find((ruleWithStatus) => rule.docRefId === ruleWithStatus.docRefId);
            if (updatedRule) {
              updatedRules.push({ ...updatedRule });
            } else {
              updatedRules.push({ ...rule });
            }
          }
          setRules(updatedRules);
        })
        .finally(() => {
          setIsRequested(false);
        })
        .catch((error) => error);
    }
  }, 1000 * 3);

  useEffect(() => {
    if (rules.length < 1 && data) {
      setRules(data.uploadedRules);
    }
    setDocIdsWithUnknownStatus([...rules.filter((rule) => !rule.targetStatus).map((rule) => rule.docRefId)]);
  }, [data, rules]);

  const ruleTabs = () => {
    const tabs = [];
    if (amountOfModifiedRules) {
      tabs.push(
        <Tab key="modified" tabPanelId="modified">
          Modified rules ({amountOfModifiedRules})&nbsp;
          <HelperText shouldValidate={modifiedErrors} valid={!modifiedErrors} />
        </Tab>
      );
    }
    if (amountOfNewRules) {
      tabs.push(
        <Tab key="new" tabPanelId="new">
          New rules ({amountOfNewRules})&nbsp;
          <HelperText shouldValidate={createdErrors} valid={!createdErrors} />
        </Tab>
      );
    }
    return tabs;
  };

  const tabPanels = [
    <TabPanel key="modified" tabPanelId="modified">
      <RulesDiff key="modified" rules={modified} />
    </TabPanel>,
    <TabPanel key="new" tabPanelId="new">
      <RulesDiff key="new" rules={created} />
    </TabPanel>,
  ];

  if (loading) {
    return (
      <Styled.FullPageLoader>
        <Loading text={'Processing upload...'}>
          <LoadingBall />
        </Loading>
      </Styled.FullPageLoader>
    );
  }

  return (
    <Styled.Container>
      {isVisible && (
        <Styled.RulesStatusInlineMessage
          ssrIcon={checkmarkCircleIcon}
          variant={variant}
          title={title}
          body={bodyText}
          dismissable
          onDismissClick={() => {
            setStatusMessage({ isVisible: false });
          }}
          actions={actions}
        />
      )}
      <Text tagName={'h1'} headingSize={'m'}>
        Upload ({totalRules})
      </Text>
      <Text tagName={'p'}>
        The following rules will either be created or modified. Rules which has not been changed will be ignored. In order to publish these changes to{' '}
        <strong>{policy.toUpperCase()}</strong>, press <strong>Publish</strong>.
      </Text>
      {totalRules && <Tabs tabs={ruleTabs()} tabPanels={tabPanels} defaultActiveTab={amountOfModifiedRules ? 'modified' : 'new'} />}
      {!totalRules && (
        <Styled.NoNewOrModifiedRules>
          <Text tagName={'h2'} headingSize={'m'}>
            No rule changes found in upload.
          </Text>
        </Styled.NoNewOrModifiedRules>
      )}
    </Styled.Container>
  );
};
