import React from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';

type SurveyModalProps = {
  isVisible: boolean;
  question: string;
  buttons: React.ReactNode;
  children?: React.ReactNode;
  onClickClose: () => void;
};

export const SurveyModal: React.FC<SurveyModalProps> = ({ isVisible, question, buttons, children, onClickClose }) => {
  return (
    <Modal visible={isVisible} handleCloseBtn={() => onClickClose()}>
      <Sheets size={'small'} header={<ModalHeader title={question} />} footer={<ModalFooter>{buttons}</ModalFooter>}>
        <ModalBody>{children}</ModalBody>
      </Sheets>
    </Modal>
  );
};
