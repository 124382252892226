import React, { useContext } from 'react';
import Button from '@ingka/button';
import SSRIcon from '@ingka/ssr-icon';
import arrowDown from '@ingka/ssr-icon/paths/arrow-down-to-line';
import arrowUp from '@ingka/ssr-icon/paths/arrow-up-to-line';
import chevronRight from '@ingka/ssr-icon/paths/chevron-right-small';
import ellipsis from '@ingka/ssr-icon/paths/ellipses-vertical';
import { TableBody, TableHeader } from '@ingka/table';
import Text from '@ingka/text';
import FileSaver from 'file-saver';
import { request } from 'gaxios';
import { FloatingMenu } from 'components/FloatingMenu';
import { FloatingMenuContent } from 'components/FloatingMenu/FloatingMenuContent';
import * as StyledGlobalComponents from 'components/styles';
import * as StyledLocal from 'features/PoliciesTable/styles';
import { Context as RulesContext } from 'hooks/contexts/RulesContext';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import { Policy } from 'types';
const Styled = { ...StyledGlobalComponents, ...StyledLocal };

type TableProps = {
  policies: Policy[];
  columnHeaders: string[];
};

export const PoliciesTable: React.FC<TableProps> = ({ policies, columnHeaders }) => {
  const { setUploadRulesModalValues } = useContext(RulesContext);
  const { refreshToken } = useContext(LoginContext);

  const downloadFile = async (country: string, language: string, type: string | undefined) => {
    const path = type === 'short' ? `ikea.${country}` : `${country}-${language}`;
    const token = await refreshToken();
    try {
      const res = await request<string>({
        method: 'POST',
        responseType: 'blob',
        url: `/api/redirect/rules/${path}/download`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return FileSaver.saveAs(res.data, `${path}_rules.xlsx`);
    } catch (e) {
      console.log(e);
    }
  };

  const uploadFile = async (country: string, language: string, type: string | undefined) => {
    const policy = type === 'short' ? `ikea.${country}` : `${country}-${language}`;
    setUploadRulesModalValues({ isOpen: true, country, policy });
  };

  return (
    <Styled.Table fullWidth>
      <TableHeader>
        <tr>
          {columnHeaders.map((columnHeader, index) => (
            <th role="columnheader" key={index}>
              {columnHeader}
            </th>
          ))}
        </tr>
      </TableHeader>
      <TableBody>
        {policies.map(({ country, language, modified, id, type }, index) => (
          <tr key={index} data-testid={`row-${id}`}>
            <td>
              <Text>{id?.toUpperCase()}</Text>
            </td>
            <td>
              <Text>{type === 'short' ? 'Short URL' : 'Default URL'}</Text>
            </td>
            <td>
              <Text>{modified}</Text>
            </td>
            <td>
              <Button
                text="View"
                iconPosition="trailing"
                ssrIcon={chevronRight}
                size={'small'}
                type="secondary"
                href={`/redirects/${country}/${id}`}
                data-cy={'view-button'}
              />
            </td>
            <td>
              <FloatingMenu>
                {(open, toggleOpen) => (
                  <>
                    <Styled.PillButton iconOnly ssrIcon={ellipsis} onClick={toggleOpen} data-testid={'floating-menu-btn'} size={'small'} />

                    {open && (
                      <FloatingMenuContent>
                        <Styled.Menu>
                          <Styled.MenuItem onClick={() => downloadFile(country, language, type)} data-testid={'download-xlsx-btn'}>
                            <SSRIcon paths={arrowDown} />
                            Download
                          </Styled.MenuItem>
                          <Styled.MenuItem onClick={() => uploadFile(country, language, type)} data-testid={'upload-xlsx-btn'}>
                            <SSRIcon paths={arrowUp} />
                            Upload
                          </Styled.MenuItem>
                        </Styled.Menu>
                      </FloatingMenuContent>
                    )}
                  </>
                )}
              </FloatingMenu>
            </td>
          </tr>
        ))}
      </TableBody>
    </Styled.Table>
  );
};
