import React, { useContext } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import Button from '@ingka/button';
import { Tab, TabPanel } from '@ingka/tabs';
import { AnnouncementForm } from 'components/admin/announcement/AnnouncementForm';
import { FilteredPages } from 'components/admin/FilteredPages';
import { Keywords } from 'components/admin/keywords/Keywords';
import { SiteStructureComp } from 'components/admin/siteStructure/SiteStructure';
import { Provider as SiteStructureProvider } from 'hooks/contexts/SiteStructureContext';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import { UrlSlugSync } from 'components/admin/urlSlugSync/UrlSlugSync';
import Container from 'components/common/Container';
import Tabs from 'components/common/Tabs';
import { PageHeader } from './styles';

export const tabs = [
  {
    route: 'config',
    text: 'Config',
    Component: (): JSX.Element => <Button type={'primary'} text={'Add New Country/Lang'} href={'/metadata/upload'} size={'small'} />,
  },
  {
    route: 'announcements',
    text: 'Announcements',
    Component: (): JSX.Element => <AnnouncementForm />,
  },
  {
    route: 'site-structure',
    text: 'Site Structure',
    Component: (): JSX.Element => (
      <SiteStructureProvider>
        <SiteStructureComp />
      </SiteStructureProvider>
    ),
  },
  {
    route: 'url-slug-sync',
    text: 'Url Slug Sync',
    Component: (): JSX.Element => <UrlSlugSync />,
  },
  {
    route: 'keywords',
    text: 'Keywords',
    Component: (): JSX.Element => <Keywords />,
  },
  {
    route: 'filtered-pages',
    text: 'Filtered Pages',
    Component: (): JSX.Element => <FilteredPages />,
  },
];
export const AdminPage: React.FC = () => {
  const { isAdmin } = useContext(LoginContext);
  const { push } = useHistory();
  const { tool } = useParams<{ tool: string }>();

  if (!tabs.map((tab) => tab.route).includes(tool)) {
    return <Redirect to={'/admin/config'} />;
  }

  return (
    <Container page="admin">
      <PageHeader>Admin Tools</PageHeader>
      {isAdmin() ? (
        <Tabs
          tabs={tabs.map(({ route, text }) => (
            <Tab key={route} tabPanelId={route} text={text} onClick={() => push(route)} />
          ))}
          tabPanels={tabs.map(({ route, Component }) => (
            <TabPanel key={route} tabPanelId={route}>
              {tool === route && <Component />}
            </TabPanel>
          ))}
          activeTab={tool}
        />
      ) : (
        <div>
          <p>
            <strong>You are not authorized to access admin tools</strong>
          </p>
        </div>
      )}
    </Container>
  );
};
