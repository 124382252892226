import { UploadNewLocale } from 'components/admin/UploadNewLocale';
import Container from 'components/common/Container';
import { useContext } from 'react';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';

export const UploadPage = () => {
  const { isAdmin } = useContext(LoginContext);
  return (
    <Container page="upload">
      {isAdmin() ? (
        <>
          <UploadNewLocale />
        </>
      ) : (
        <div>
          <p>
            <strong>You are not authorized to access this page</strong>
          </p>
        </div>
      )}
    </Container>
  );
};
