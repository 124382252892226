import React, { FC, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import Button from '@ingka/button';
import arrowDown from '@ingka/ssr-icon/paths/arrow-down-to-line';
import arrowUp from '@ingka/ssr-icon/paths/arrow-up-to-line';
import checkmarkCircleIcon from '@ingka/ssr-icon/paths/checkmark-circle';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import warningTriangleIcon from '@ingka/ssr-icon/paths/warning-triangle';
import Text from '@ingka/text';
import FileSaver from 'file-saver';
import { request } from 'gaxios';
import { CheckboxFields, CheckboxGroup } from 'components/CheckboxGroup';
import { ContainerWrapper } from 'components/common/Container';
import { Pagination } from 'components/Pagination';
import { SearchField } from 'components/Search';
import { Rules } from 'features/Rules';
import { DeleteRuleModal } from 'features/Rules/DeleteRuleModal';
import { Context as RulesContext } from 'hooks/contexts/RulesContext';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import { Context as StatusMessageContext, StatusMessageState } from 'hooks/contexts/StatusMessageContext';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { Column as RuleTableColumn } from 'types';
import { useFetchRules } from './rules.service';
import * as Styled from 'pages/styles';

type LocaleDetailProps = RouteComponentProps<{ policy: string; country: string }, {}, StatusMessageState | undefined>;
export const RulesPage: FC<LocaleDetailProps> = ({
  match: {
    params: { policy, country },
  },
  history: {
    location: { state },
  },
}) => {
  const defaultColumns: RuleTableColumn[] = [
    { label: 'From', value: 'fromRule', visible: true, field: 'matchURL' },
    { label: 'To', value: 'toRule', visible: true, field: 'targetURL' },
    { label: 'Policy name', value: 'policyName', visible: true, field: 'name' },
    { label: 'Usage', value: 'invocations', visible: true, field: 'invocations' },
    { label: 'Start date', value: 'dateStart', visible: true, field: 'dateStart' },
    { label: 'End date', value: 'dateEnd', visible: true, field: 'dateEnd' },
    { label: 'Type', value: 'redirectType', visible: true, field: 'redirectType' },
    { label: 'Target status', value: 'targetStatus', visible: true, field: 'targetStatus' },
    { label: 'Edited by', value: 'userModifiedBy', visible: true, field: 'userModifiedBy' },
  ];

  const COLUMNS_VERSION = 1; // Change this if the defaultColumns are modified

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, updateItemsPerPage] = useState(50);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedRules, setSelectedRules] = useState<string[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [columns, setColumns] = useLocalStorage<RuleTableColumn[]>('config:rules-columns', defaultColumns);
  const [currentColumnsVersion, setCurrentColumnsVersion] = useLocalStorage<number>('config:rules-columns-version', 0);
  const { refreshToken } = useContext(LoginContext);

  const {
    state: { refetchAfter, sort },
    setSelectedRuleValue,
    setUploadRulesModalValues,
  } = useContext(RulesContext);

  const { state: statusMessageState, setStatusMessage } = useContext(StatusMessageContext);
  const { title, isVisible, variant, bodyText, actions } = state ?? statusMessageState;

  const statusMessageIcon = { positive: checkmarkCircleIcon, cautionary: warningTriangleIcon };

  const { data, loading } = useFetchRules({
    refetchAfter,
    policy,
    pageLimit: itemsPerPage,
    page: currentPage,
    searchValue,
    sort: `${sort.direction ?? 'desc'}-${sort.field ?? 'dateModified'}`,
  });
  const { rules, totalRules } = data ?? { rules: [], totalRules: 0 };

  useEffect(() => {
    if (COLUMNS_VERSION !== currentColumnsVersion) {
      setColumns(defaultColumns);
      setCurrentColumnsVersion(COLUMNS_VERSION);
    }
  }, []);

  const visibleColumns = columns.filter((column) => column.visible);
  const defaultCheckBoxes: CheckboxFields[] = columns
    .map(({ label, value, visible }) => ({
      checked: visible,
      label,
      value,
      id: value,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const downloadFile = async (policy: string) => {
    const token = await refreshToken();
    try {
      const res = await request<string>({
        method: 'POST',
        responseType: 'blob',
        url: `/api/redirect/rules/${policy}/download`,
        params: { search: searchValue },
        headers: { Authorization: `Bearer ${token}` },
        data: { docRefIds: selectedRules },
      });
      return FileSaver.saveAs(res.data, `${policy}_rules.xlsx`);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteClick = () => {
    setSelectedRuleValue({});
    setIsDeleteModalOpen(true);
  };

  const toggleSelectAll = () => {
    if (selectedRules.length) {
      return clearSelectedRules();
    }
    const ruleIds = rules.map((rule) => rule.docRefId);
    setSelectedRules(ruleIds);
  };

  const onRuleToggle = (docRefId: string) =>
    setSelectedRules((rules) => {
      if (rules.includes(docRefId)) {
        return rules.filter((ruleId) => ruleId !== docRefId);
      }
      return Array.from(new Set([...rules, docRefId]));
    });

  const clearSelectedRules = () => {
    if (selectedRules.length) {
      setSelectedRules([]);
    }
  };

  const onRulesColumnsApply = (columnsToDisplay: string[]) =>
    setColumns((prevColumns) => {
      return prevColumns.map((column) => {
        const isColumnVisible = columnsToDisplay.includes(column.value);
        return { ...column, visible: isColumnVisible };
      });
    });

  return (
    <ContainerWrapper>
      {isVisible && (
        <Styled.RulesStatusInlineMessage
          ssrIcon={statusMessageIcon[variant]}
          variant={variant}
          title={title}
          body={bodyText}
          dismissable
          onDismissClick={() => {
            setStatusMessage({ isVisible: false });
            window.history.replaceState({}, '');
          }}
          actions={actions}
        />
      )}

      <DeleteRuleModal
        isVisible={isDeleteModalOpen}
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}
        onSubmit={() => {
          setIsDeleteModalOpen(false);
          clearSelectedRules();
        }}
        selectedRules={selectedRules}
      />

      <Styled.SearchWrapper>
        <SearchField
          id="rules_search_input"
          data-testid={'rules-search-input'}
          placeholder="Search"
          type="text"
          onLetterSearch={(value) => {
            setSearchValue(value);
            clearSelectedRules();
            setCurrentPage(1);
          }}
        />
        <CheckboxGroup onApply={onRulesColumnsApply} defaultCheckboxes={defaultCheckBoxes} />
      </Styled.SearchWrapper>

      <Styled.RulesFilteringWrapper>
        <Button
          ssrIcon={arrowDown}
          text={`Download ${selectedRules.length ? `(${selectedRules.length})` : 'all'}`}
          type={'secondary'}
          onClick={() => downloadFile(policy)}
          data-testid={'rules-download-btn'}
          size={'small'}
        />

        <Button
          ssrIcon={arrowUp}
          text={'Upload'}
          type={'secondary'}
          onClick={() => setUploadRulesModalValues({ isOpen: true, country, policy })}
          data-testid={'rules-upload-btn'}
          size={'small'}
        />

        <Button
          ssrIcon={trashCan}
          text={`Delete ${selectedRules.length ? `(${selectedRules.length})` : ''}`}
          type={'secondary'}
          onClick={() => deleteClick()}
          disabled={selectedRules.length < 1}
          data-testid={'rules-delete-btn'}
          size={'small'}
        />

        <Text tagName="span" bodySize="m">
          {totalRules} items found
        </Text>

        <Pagination
          totalItems={totalRules}
          itemsPerPage={itemsPerPage}
          onPageChange={(pageNumber) => {
            setCurrentPage(pageNumber);
            clearSelectedRules();
          }}
          onItemsPerPageChange={(e) => {
            updateItemsPerPage(Number(e.target.value));
            clearSelectedRules();
          }}
        />
      </Styled.RulesFilteringWrapper>
      <Rules
        selectedRules={selectedRules}
        isLoading={loading}
        rules={rules}
        onRuleToggle={onRuleToggle}
        toggleSelectAll={toggleSelectAll}
        clearSelectedRules={clearSelectedRules}
        columns={visibleColumns}
        policy={policy}
      />
    </ContainerWrapper>
  );
};
