import styled from '@emotion/styled';
import InlineMessage from '@ingka/inline-message';
import Pill from '@ingka/pill';
import IngkaTable from '@ingka/table';
import Text from '@ingka/text';
import { fontWeightBold, space25, space50, space125, space300, space400 } from '@ingka/variables/design-tokens';
import { FlexContainer } from 'components/FlexContainer';
import { space75 } from '@ingka/variables';

export const Badge = styled.div<{ backgroundColor?: string; color: string }>`
  padding: ${space25} ${space50};
  font-size: 10px;
  font-weight: bold;
  border-radius: ${space50};
  border: 1px solid ${(props) => props.color || 'gray'};

  flex: 0 1 auto;
  align-self: flex-start;
  color: ${(props) => props.color || 'white'};

  float: left;
  margin: ${space25} ${space25} ${space25} 0;
`;

export const MetadataItemWrapper = styled.div`
  padding: 0 ${space400} ${space125} ${space300};
`;

export const AutosuggestWrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  padding: 1rem;
`;

export const AutosuggestBox = styled.div`
  position: absolute;
  z-index: 10;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  margin-top: -1rem;
  flex-direction: column;
  background-color: white;
  border: 1px solid lightgray;

  & span {
    padding: 0.5rem;
    border-bottom: 1px solid #eee;
  }

  & span:last-child {
    border-bottom: none;
  }

  & span:hover {
    background-color: #eee;
    cursor: pointer;
    display: block;
  }
`;

interface ReviewWarningProps {
  clickable: boolean;
}

export const ReviewWarning = styled.span<ReviewWarningProps>`
  cursor: ${(props) => (props.clickable ? 'pointer' : 'normal')};
  display: inline-block;
`;

export const BadgesColumn = styled.div`
  flex: 0 0 150px;
  padding-bottom: 1rem;
`;

export const StatsColumn = styled.div`
  width: 100%;
`;

export const SearchDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0.3rem;
`;

export const SearchDataItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0.6rem;
`;

export const SearchDataLabel = styled.span`
  font-weight: bold;
  margin: 0 0.6rem;
`;

export const BodyCopy = styled.div<{ isExpanded: boolean }>`
  overflow: hidden;
  height: ${(props) => (props.isExpanded ? undefined : '80px')};
`;

export const FieldHelper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 12px;
`;

export const ModalHeader = styled.div`
  width: 100%;
  height: 70px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  background-color: #003e72;
  padding: ${space75};
`;

export const HeaderText = styled(Text)`
  color: #ffdb00;
  margin-bottom: 0 !important;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;

  &:first-of-type {
    font-size: 12px;
    font-weight: 400;
    text-transform: none;
  }
`;

export const FormGroup = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 12px;
`;

export const FormGroupItem = styled.div`
  flex-basis: calc(50% - 10px);

  .form-field,
  .form-field > .input-field,
  .form-field > .text-area {
    margin-bottom: 0.25rem;
  }
`;
export const MetaDataFilteringWrapper = styled(FlexContainer)`
  align-items: center;
  margin-bottom: 20px;

  div:last-child {
    margin-left: auto;
    align-items: center;
  }
`;

export const DraftInlineMessage = styled(InlineMessage)`
  margin-bottom: 1rem;
`;

export const Table = styled(IngkaTable)<{ offsettop?: number }>`
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  height: ${(props) => `calc(100vh - ${props.offsettop}px - ${space125} - ${space50})`};

  tbody,
  td {
    vertical-align: middle;
    word-break: break-word;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
`;

interface MetaDataCollapsedTableRowProps {
  clickable?: boolean;
}

export const MetaDataCollapsedTableRow = styled.tr<MetaDataCollapsedTableRowProps>`
  cursor: ${(props) => (props.clickable ? 'pointer' : 'normal')};

  td:has(button) {
    min-width: 7rem;
    padding-right: 0;
  }
`;

export const MetaDataCollapsedRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  > div {
    width: 50%;
  }
`;

export const MetaDataCollapsedGroupedCell = styled.div`
  display: flex;
  align-items: center;

  > div {
    width: 25%;

    div + div {
      color: #003e72;
      font-weight: ${fontWeightBold};
    }
  }
`;

export const MetaDataCollapsedGroupedCellKeywords = styled.div`
  display: flex;
  align-items: center;

  > div {
    width: 33%;

    div + div {
      color: #003e72;
      font-weight: ${fontWeightBold};
    }
  }
`;

export const MetaDataCollapsedHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 47px;
  max-height: 47px;
  gap: 8px;

  > div {
    width: 50%;
  }
`;
export const MetaDataCollapsedWrapper = styled.tr`
  background-color: #e6ecf1 !important;
  padding: 0 8px 8px 8px;
`;

export const MetaDataCollapsedWrapperCell = styled.td`
  padding-left: 8px !important;
`;

MetaDataCollapsedWrapperCell.defaultProps = {
  colSpan: 10,
};

interface SearchRowContainerProps {
  squareDisplayActive?: boolean;
}

export const SearchRowContainer = styled(FlexContainer)<SearchRowContainerProps>`
  margin-bottom: 0.5rem;

  ${(props) =>
    props.squareDisplayActive
      ? `> div:nth-last-of-type(1) {
    margin-left: auto;
  }`
      : `> div:nth-last-of-type(2) {
    margin-left: auto;
  }`}
`;

export const ChevronBtn = styled(Pill)`
  pointer-events: none;
  background-color: transparent;
`;

export const MetaDataButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${space50};
  justify-content: flex-end;
  padding-right: ${space125};
`;

export const AlignedCell = styled.td<{ rightAlign?: boolean }>`
  text-align: ${({ rightAlign = false }) => (rightAlign ? 'right' : 'left')} !important;
`;

export const AlignedHeader = styled.th<{ rightAlign?: boolean }>`
  text-align: ${({ rightAlign = false }) => (rightAlign ? 'right' : 'left')} !important;
`;
